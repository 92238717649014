import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Grid } from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Select from "react-select"
import API from "../../api"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
// import cresusLogin from "../../assets/images/banner_login.jpg"
import axios from "axios"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"

const Login = props => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [anneeScolaire, setAnneeScolaire] = useState([])
  const [api_password, setApiPassword] = useState("FAaaK32p0Xq04T0e")
  const [selectAnneeScolaire, setSelectAnneeScolaire] = useState("")
  const [verifier, setVerifier] = useState(false)
  const [loading, setLoading] = useState(true)

    useEffect(async () => {
        const res = await axios
            .get("https://scolarite.backcresus-institut.ovh/api/annee")
            .then(res => {
                setAnneeScolaire(res.data.Annee[0].ListAnnee)
                setSelectAnneeScolaire(res.data.Annee[0].CurrentAnnee[0])
            })
    }, [])

  const signIn = async () => {
    setLoading(false)
    var anneeScolaire_id = selectAnneeScolaire
    // login stage
    const res = await axios.post(
      "https://backstage.isggb.com/api/login",
      {
        email,
        password,
        api_password: api_password,
        anneeScolaire_id,
      }
    )
    // login entreprise
    const resS = await axios.post(
      "https://backentreprise.isggb.com/api/login",
      {
        email: "gerant@cresus.pro",
        password: "cresus28",
        anneeScolaire_id,
      }
    )
    // login scolarité
    const resD = await axios.post(
      "https://scolarite.backcresus-institut.ovh/api/login",
      {
        email: "gerant@cresus.pro",
        password: "cresus@2023",
        anneeScolaire_id,
      }
    )
    if (res.data.status === 200) {
      localStorage.setItem("userAuthStage", JSON.stringify(res.data))
      localStorage.setItem("userAuthEntreprise", JSON.stringify(resS.data))
      localStorage.setItem("userAuthScolarite", JSON.stringify(resD.data))
      props.history.push("/dashboard")
      window.location.reload(false)
    } else {
      ;<Redirect to="/" />
      toast.error("⛔ Veuillez vérifier votre email et mot de passe !", {
        containerId: "A",
      })
    }
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            backgroundImage: `url(${cresusFond})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div style={{ paddingTop: "7%" }}>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#485ec4",
                              textAlign: "center",
                            }}
                          >
                            Espace service de stage
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <div className="p-2">
                        <AvForm className="form-horizontal">
                          <div className="mb-3">
                            <AvField
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              errorMessage="* E-mail obligatoire"
                              required
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="password"
                              type="password"
                              required
                              placeholder="Mot de passe"
                              errorMessage="* Mot de passe obligatoire"
                              onChange={e => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Année universitaire
                            </Label>
                            <Select
                              options={anneeScolaire}
                              isSearchable={true}
                              onChange={e => setSelectAnneeScolaire(e)}
                              value={selectAnneeScolaire}
                            />
                          </div>
                          {/*<div className="mb-3">*/}
                          {/*  <Label for="basicpill-firstname-input1"></Label>*/}
                          {/*  <ReCAPTCHA*/}
                          {/*    sitekey="6Lebx24fAAAAABJtE8xEeUBYfb5MrDCSyjBL2J3u"*/}
                          {/*    onChange={() => setVerifier(true)}*/}
                          {/*  />*/}
                          {/*</div>*/}
                          <div className="mt-3 d-grid">
                            <button
                              onClick={signIn}
                              className="btn btn-primary btn-block"
                              type="submit"
                              // disabled={!verifier}
                            >
                              Connexion
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by{" "}
                        <a href="https://cresus.pro/">cresus.pro</a>
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
          }}
        >
          <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
        </div>
      )}
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
